<template>
  <div>
    <div class="header"></div>
    <img src="~@/assets/about.jpg" class="img" />
  </div>
</template>

<script>
export default {
  name: 'About'
};
</script>

<style lang="scss" scoped>
.img {
  width: 100vw;
}
</style>
